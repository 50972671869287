/**
 * Labstep
 *
 * @module components/Layout/Links
 * @desc Navlinks + Switch
 */

import classnames from 'classnames';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import NavLink from 'labstep-web/core/NavLink';
import Switch from 'labstep-web/core/Switch';
import { navigation } from 'labstep-web/services/navigation';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import { matchPath } from 'react-router';
import { NavLinkProps } from 'react-router-dom';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import styles from './styles.module.scss';
import {
  ILayoutLinksVerticalLinkProps,
  ILayoutLinksVerticalProps,
} from './types';

export const Group: React.FC<{ label: string }> = (props) => {
  return (
    <div className={styles.groupContainer}>
      <div className={styles.groupHeading}>
        {props.label}
        <hr />
      </div>
      {props.children}
    </div>
  );
};

export const LayoutLinksVerticalLink: React.FC<
  ILayoutLinksVerticalLinkProps
> = ({ link }) => {
  const isActive: NavLinkProps['isActive'] = (match, location) =>
    link.routes
      ? !!link.routes.find(
          (route: keyof IRouting) =>
            !!matchPath(location.pathname, {
              path: navigation.get(route),
              exact: true,
            }),
        )
      : !!matchPath(location.pathname, {
          path: navigation.get(link.route.to),
          exact: true,
        });

  return (
    <NavLink
      key={link.route.to}
      className={classnames(styles.verticalLink)}
      to={link.route.to}
      params={link.route.params}
      query={link.route.query}
      isActive={isActive}
      exact
    >
      <Icon name={link.icon} />
      {link.children}
      {link.premiumFeature && (
        <PremiumFeatureLockIcon
          premiumFeature={link.premiumFeature}
        />
      )}
    </NavLink>
  );
};

export const LayoutLinksVertical: React.FC<
  ILayoutLinksVerticalProps
> = ({ links, groups }) => (
  <Flex>
    <div className={styles.verticalContainer}>
      {links.map((link) => (
        <LayoutLinksVerticalLink key={link.icon} link={link} />
      ))}
      {groups.map((group) => (
        <Group key={group.label} label={group.label}>
          {group.links.map((link) => (
            <LayoutLinksVerticalLink key={link.icon} link={link} />
          ))}
        </Group>
      ))}
    </div>
    <Switch
      links={links
        .concat(groups.map((group) => group.links).flat()) // flatten groups into links
        .flatMap((link) =>
          link.routes
            ? link.routes.map((route) => ({
                render: link.render,
                route: { to: route },
              }))
            : { render: link.render, route: { to: link.route.to } },
        )}
    />
  </Flex>
);

export default LayoutLinksVertical;
