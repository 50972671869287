/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Seats
 * @desc Select seats
 */

import Message from 'labstep-web/core/Message';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';
import { intervalAdverb } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { IOrganizationPlanManagerSeatsProps } from './types';

export const OrganizationPlanManagerSeats: React.FC<
  IOrganizationPlanManagerSeatsProps
> = ({
  organization,
  priceCurrency,
  activePlanPrice,
  maxSeats,
  setMaxSeats,
}) => {
  const currencyOption =
    activePlanPrice.currency_options[priceCurrency];

  const seatPrice = CurrencyService.print(
    currencyOption.unit_amount,
    priceCurrency.toUpperCase() as Currency,
    false,
  );
  const totalPrice = CurrencyService.print(
    currencyOption.unit_amount * maxSeats,
    priceCurrency.toUpperCase() as Currency,
    false,
  );

  return (
    <div className={styles.container}>
      <div className={styles.plan}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div className={styles.userCounter}>
            <div>Your organization has</div>
            <div data-testid="organization-users">
              {organization.seats}
            </div>
            <div>users with a seat</div>
          </div>
          <div className={styles.userCounter}>
            <div>I want to pay for</div>
            <div>
              <div
                data-testid="minus-button"
                className={styles.seatAdjustButton}
                onClick={() => {
                  setMaxSeats(
                    Math.max(organization.seats, maxSeats - 1),
                  );
                }}
              >
                -
              </div>
              <div
                data-testid="selected-seat-count"
                className={styles.selectedSeatCount}
              >
                {maxSeats}
              </div>
              <div
                data-testid="plus-button"
                className={styles.seatAdjustButton}
                onClick={() => {
                  setMaxSeats(Math.min(5, maxSeats + 1));
                }}
              >
                +
              </div>
            </div>
            <div>
              <i>Labstep Basic</i> Seats
            </div>
          </div>
        </div>
      </div>

      <div className={styles.plan}>
        <div>Total cost</div>
        <div data-testid="seat-price" className={styles.amount}>
          {seatPrice}
          {' per seat, paid '}
          {intervalAdverb[activePlanPrice.interval]}
        </div>
        <div data-testid="total-price" className={styles.totalPrice}>
          <span className={styles.price}>{totalPrice}</span>
          <span className={styles.interval}>
            /{activePlanPrice.interval}
          </span>
        </div>
      </div>

      <div className={styles.plan}>
        <Message className={styles.message}>
          Remember that the Labstep Basic plan has a{' '}
          <b>limit of 5 seats</b>. If you require more seats you’ll
          need to upgrade to at least the Professional plan—so
          remember to plan ahead!
        </Message>
      </div>
    </div>
  );
};
