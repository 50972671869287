import { plainToInstance } from 'class-transformer';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';

export const entityUserRole: EntityUserRole = plainToInstance(
  EntityUserRole,
  {
    permission_entity_info: {
      id: 1,
      guid: null,
      entityName: 'entity_user_role',
    },
    id: 1,
    created_at: '2024-03-25T13:45:27+00:00',
    updated_at: '2024-03-25T13:45:27+00:00',
    deleted_at: null,
    author: {
      name: 'owner@labstep.com owner@labstep.com',
      experiment_workflow_count: 1,
      resource_count: 0,
      protocol_collection_count: 0,
      permission_entity_info: {
        id: 1,
        guid: 'owner@labstep.com',
        entityName: 'user',
      },
      id: 1,
      username: 'owner@labstep.com',
      created_at: '2024-03-23T13:45:26+00:00',
      updated_at: '2024-03-25T13:45:26+00:00',
      deleted_at: null,
      consented_at: null,
      monthly_report_optout_at: null,
      first_name: 'owner@labstep.com',
      last_name: 'owner@labstep.com',
      saml_id: null,
      is_enterprise: false,
      is_signup_enterprise: false,
      profile: {
        permission_entity_info: {
          id: 1,
          guid: 'owner@labstep.com',
          entityName: 'user',
        },
        id: 1,
        created_at: '2024-03-25T13:45:26+00:00',
        updated_at: '2024-03-25T13:45:26+00:00',
        deleted_at: null,
        photo: null,
      },
      allowed_actions: ['*'],
      guid: 'owner@labstep.com',
    },
    name: 'Reviewer',
    description: 'Can review experiments',
    owner: {
      purchase_order_template_id: null,
      user_count: 5,
      permission_entity_info: {
        id: 6,
        guid: 'e6ca5d47-6e94-4638-abd7-9dd3013e13d9',
        entityName: 'group',
      },
      id: 6,
      created_at: '2024-03-25T13:45:26+00:00',
      updated_at: '2024-03-25T13:45:26+00:00',
      deleted_at: null,
      member_permission: 'edit',
      is_completion_checklist_enabled: false,
      name: 'Group with custom role',
      description: null,
      organization: {
        has_trial_ended: false,
        has_metabase_id: false,
        admin_email: 'owner@labstep.com',
        id: 1,
        created_at: '2024-03-25T13:45:26+00:00',
        updated_at: '2024-03-25T13:45:26+00:00',
        deleted_at: null,
        trial_ends_at: '2024-04-24T13:45:26+00:00',
        identifier: 'labstep',
        currency: null,
        name: 'Labstep',
        logo_url: null,
        stripe_subscription_id: null,
        stripe_customer_id: null,
        stripe_price_id: null,
        is_saml_enabled: false,
        is_allow_member_create_group: true,
        premium_features: ['custom_permissions', 'time_machine'],
        author: {
          name: 'owner@labstep.com owner@labstep.com',
          experiment_workflow_count: 1,
          resource_count: 0,
          protocol_collection_count: 0,
          permission_entity_info: {
            id: 1,
            guid: 'owner@labstep.com',
            entityName: 'user',
          },
          id: 1,
          username: 'owner@labstep.com',
          created_at: '2024-03-23T13:45:26+00:00',
          updated_at: '2024-03-25T13:45:26+00:00',
          deleted_at: null,
          consented_at: null,
          monthly_report_optout_at: null,
          first_name: 'owner@labstep.com',
          last_name: 'owner@labstep.com',
          saml_id: null,
          is_enterprise: false,
          is_signup_enterprise: false,
          profile: {
            permission_entity_info: {
              id: 1,
              guid: 'owner@labstep.com',
              entityName: 'user',
            },
            id: 1,
            created_at: '2024-03-25T13:45:26+00:00',
            updated_at: '2024-03-25T13:45:26+00:00',
            deleted_at: null,
            photo: null,
          },
          allowed_actions: ['*'],
          guid: 'owner@labstep.com',
        },
        is_trialling: true,
        is_payment_overdue: false,
        allowed_actions: ['*', 'user_organization:*'],
        guid: 'c772c90e-e8c4-4b77-ae3d-c4fbd918d213',
      },
      profile: {
        permission_entity_info: {
          id: 6,
          guid: 'e6ca5d47-6e94-4638-abd7-9dd3013e13d9',
          entityName: 'group',
        },
        id: 11,
        created_at: '2024-03-25T13:45:26+00:00',
        updated_at: '2024-03-25T13:45:26+00:00',
        deleted_at: null,
        photo: null,
      },
      is_home: false,
      allowed_actions: [
        '*',
        'device:create',
        'experiment_workflow:create',
        'experiment_workflow_template:create',
        'protocol_collection:create',
        'resource_item:create',
        'resource_item_template:create',
        'resource:create',
        'resource_template:create',
        'resource_location:create',
        'order_request:create',
        'order_request_template:create',
        'purchase_order:create',
        'purchase_order_template:create',
        'tag:create',
        'folder:create',
        'workflow:create',
        'entity_export:*',
        'purchase_order:add_remove',
        'user_group:add_remove',
        'group:edit',
        'group:delete',
        'user_group:*',
      ],
      guid: 'e6ca5d47-6e94-4638-abd7-9dd3013e13d9',
    },
    entity_users: [],
    allowed_actions: ['*', 'entity_export:*'],
    guid: null,
  },
);
