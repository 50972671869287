import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import {
  PostFilterAvailableItemsMax,
  PostFilterAvailableItemsMin,
} from 'labstep-web/services/postFilter/filters/availableItems';
import React from 'react';

export const FilterAvailableResourceItemCountActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ onRemoveClick, node }) => {
  const { available_resource_item_count: minAttribute } =
    PostFilterAvailableItemsMin.getAttributeValues(node);
  const available_resource_item_count_min = minAttribute?.value;

  const { available_resource_item_count: maxAttribute } =
    PostFilterAvailableItemsMax.getAttributeValues(node);
  const available_resource_item_count_max = maxAttribute?.value;
  if (
    !available_resource_item_count_max &&
    !available_resource_item_count_min
  ) {
    return null;
  }

  return (
    <>
      {available_resource_item_count_min && (
        <RemoveLabel
          name={`≥ ${available_resource_item_count_min} available items`}
          onRemoveClick={onRemoveClick}
        />
      )}
      {available_resource_item_count_max && (
        <RemoveLabel
          name={`≤ ${available_resource_item_count_max} available items`}
          onRemoveClick={onRemoveClick}
        />
      )}
    </>
  );
};

export default FilterAvailableResourceItemCountActivePost;
