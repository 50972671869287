/**
 * Labstep
 *
 * @module components/Organization/Activity
 * @desc Organization Activity
 */

import { OrganizationMetabaseContainer } from 'labstep-web/containers/Metabase';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import React, { useEffect } from 'react';
import { IOrganizationActivityProps } from './types';

export const OrganizationActivity: React.FC<
  IOrganizationActivityProps
> = ({ getMetabaseUrl, setActiveMetabaseUrl, activeMetabaseUrl }) => {
  useEffect(() => {
    getMetabaseUrl();
    return () => {
      setActiveMetabaseUrl(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmptyState
      src="/img/error/maintenance.svg"
      title="Under Maintenance"
      explanation="The activity dashboard is undergoing maintenance to improve your user experience. Please check back later."
    />
    // TODO: Uncomment this when the metabase dashboard is ready
    // <iframe
    //   title="Organization activity"
    //   src={activeMetabaseUrl}
    //   frameBorder="0"
    //   width="100%"
    //   height="650"
    //   allowTransparency
    // />
  );
};

export const OrganizationActivityContainer: React.FC = () => (
  <OrganizationMetabaseContainer>
    {(props) => <OrganizationActivity {...props} />}
  </OrganizationMetabaseContainer>
);

export default OrganizationActivityContainer;
