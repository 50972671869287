/**
 * Labstep
 *
 * @module components/Filter/DateRange
 * @desc Date range filter
 */

import React from 'react';
import moment from 'moment';
import { format } from 'labstep-web/services/date.service';
import DateTimePicker from 'labstep-web/core/DateTimePicker';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { STRINGS } from 'labstep-web/strings';
import { PostFilterCreatedAt } from 'labstep-web/services/postFilter/filters/createdAt';
import { IDateProps, IDateRangeFilterProps } from './types';

export const onDatePickerChange = (
  { startDate, endDate }: IDateProps,
  setParams?: any,
  addPostFilter?: IDateRangeFilterProps['addPostFilter'],
) => {
  const created_at_from = !startDate ? undefined : format(startDate);
  const created_at_to = !endDate
    ? undefined
    : format(moment(endDate).endOf('day'));

  if (addPostFilter) {
    addPostFilter(
      PostFilterCreatedAt.getNode([created_at_from, created_at_to]),
    );
  } else {
    setParams({ created_at_from, created_at_to });
  }
};

const DateRangeFilter: React.FC<IDateRangeFilterProps> = ({
  setParams,
  searchParams,
  addPostFilter,
}) => (
  <Modal
    header="Date Range"
    content={({ toggleModal }) => (
      <DateTimePicker
        options={{
          defaultDate: [
            searchParams?.created_at_from,
            searchParams?.created_at_to,
          ],
          mode: 'range',
          maxDate: 'today',
          dateFormat: 'Y-m-d',
        }}
        placeholder="Select dates"
        onClose={(selectedDates) => {
          onDatePickerChange(
            {
              startDate: selectedDates[0],
              endDate: selectedDates[1],
            },
            setParams,
            addPostFilter,
          );
          toggleModal();
        }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        icon="calendar alternate"
        text={STRINGS.filter.header.date_range}
        onClick={toggleModal}
      />
    )}
  />
);

export default DateRangeFilter;
