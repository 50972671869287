/**
 * Labstep
 *
 * @module components/ResourceItem/Action/CreateProtocolValue
 * @desc Create ResourceItem for ProtocolValue output
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import ResourceItemActionCreate from 'labstep-web/components/ResourceItem/Action/Create';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { IResourceItemActionCreateProtocolValueProps } from './types';

const defaultActionComponentProps: IActionComponentProps = {
  type: 'button',
  text: 'Create Item',
  elementProps: { basic: true },
};

export const ResourceItemActionCreateProtocolValue: React.FC<
  IResourceItemActionCreateProtocolValueProps
> = ({ protocolValue, ...rest }) => (
  <Can
    entityName={protocolValue.entityName}
    id={protocolValue.guid}
    action={Action.edit}
  >
    {protocolValue.resource ? (
      <EntityActionCreate
        entityName={ResourceItem.entityName}
        body={{
          resource_id: protocolValue.resource.id,
          amount: protocolValue.amount,
          unit: protocolValue.unit,
          protocol_value_origin_guid: protocolValue.guid,
        }}
        actionComponentProps={defaultActionComponentProps}
        {...rest}
      />
    ) : (
      <ResourceItemActionCreate
        withRedirect={false}
        resourceTemplate={
          protocolValue.resource_template || undefined
        }
        actionComponentProps={defaultActionComponentProps}
        protocolValueOrigin={protocolValue}
        {...rest}
      />
    )}
  </Can>
);

export default ResourceItemActionCreateProtocolValue;
