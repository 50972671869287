/**
 * Labstep
 *
 * @module services/navigation
 * @desc Service for navigation - Holds all the routes
 */

import qs from 'query-string';
import { IRouting } from './navigation.types';

export const routing: IRouting = {
  root: () => '',
  /* ------- Authentication ------- */
  saml_get_login_url: () => '/user/login/saml-get-url',
  saml_login: ({ token = ':token' }) => `/login/saml/token/${token}`,
  saml_login_new: ({
    organizationSamlIdentifier = ':organizationSamlIdentifier',
    token = ':token',
  }) => `/login/saml/${organizationSamlIdentifier}/token/${token}`,
  forgot_password: () => '/forgot-password',
  reset_password: ({ token = ':token' }) =>
    `/resetting/reset/${token}`,
  set_password: ({ token = ':token' }) => `/set-password/${token}`,
  signup: () => '/signup',
  signup_enterprise: () => '/signup-industry',
  verify_email: ({ token = ':token' }) => `/register/${token}`,
  login: () => '/login',
  logout_jupyter: () => '/jupyter/logout',
  login_saml: ({
    organizationIdentifier = ':organizationIdentifier',
  }) => `/login/${organizationIdentifier}`,
  // Authentication for box
  oauth_box: () => `/oauth/box`,
  /* ------- Main App ------- */
  app_homepage: () => '/',
  onboarding_overview: () => '/onboarding/overview',
  onboarding_create_workspace: () => '/onboarding/create-workspace',
  onboarding_invite: () => '/onboarding/invite',
  // Legacy index pages - Redirect
  overview: () => '/overview',
  tag_index: () => '/tags',
  protocol_collection_index: () => '/protocol-collections',
  experiment_workflow_index: () => '/experiment-workflows',
  experiment_workflow_template_index: () =>
    '/experiment-workflow-templates',
  order_request_index: () => '/order-requests',
  purchase_order_index: () => '/purchase-orders',
  file_index: () => '/files',
  device_index: () => '/devices',
  device_template_index: () => '/device-categories',
  resource_index: () => '/resources',
  resource_item_index: () => '/resource-items',
  resource_location_index: () => '/resource-locations',
  resource_template_index: () => '/resource-categories',
  user_index: () => '/users',
  settings: () => '/settings',
  settings_notifications: () => '/settings-notifications',
  settings_order_info: () => '/settings-order-info',
  // Referral
  referral: () => '/referral',
  // Order Request
  order_request_show: ({ id = ':id' }) => `/order-request/${id}`,
  order_request_show_metadata: ({ id = ':id' }) =>
    `/order-request/${id}/metadata`,
  order_request_show_resource_items: ({ id = ':id' }) =>
    `/order-request/${id}/items`,
  order_request_show_thread: ({ id = ':id' }) =>
    `/order-request/${id}/thread`,
  order_request_show_activity: ({ id = ':id' }) =>
    `/order-request/${id}/activity`,
  // Organization
  organization_show: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}`,
  organization_show_users: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/users`,
  organization_show_permissions: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/permissions`,
  organization_show_activity: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/activity`,
  organization_show_billing: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/billing`,
  organization_show_security: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/security`,
  organization_show_export: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/export`,
  organization_show_sso: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/sso`,
  organization_show_settings: ({ identifier = ':identifier' }) =>
    `/organization/${identifier}/settings`,
  // Purchase Order
  purchase_order_show: ({ id = ':id' }) => `/purchase-order/${id}`,
  purchase_order_print: ({ id = ':id' }) =>
    `/purchase-order/${id}/print`,
  purchase_order_show_metadata: ({ id = ':id' }) =>
    `/purchase-order/${id}/metadata`,
  purchase_order_show_thread: ({ id = ':id' }) =>
    `/purchase-order/${id}/thread`,
  purchase_order_show_activity: ({ id = ':id' }) =>
    `/purchase-order/${id}/activity`,
  // Protocol Collection
  protocol_collection_show_values: ({ id = ':id' }) =>
    `/protocol-collection/${id}/inventory`,
  protocol_collection_show_timers: ({ id = ':id' }) =>
    `/protocol-collection/${id}/timers`,
  protocol_collection_show_steps: ({ id = ':id' }) =>
    `/protocol-collection/${id}/steps`,
  protocol_collection_show_experiments: ({ id = ':id' }) =>
    `/protocol-collection/${id}/experiments`,
  protocol_collection_show: ({ id = ':id' }) =>
    `/protocol-collection/${id}`,
  protocol_collection_show_thread: ({ id = ':id' }) =>
    `/protocol-collection/${id}/thread`,
  protocol_collection_show_activity: ({ id = ':id' }) =>
    `/protocol-collection/${id}/activity`,
  protocol_collection_show_metadata: ({ id = ':id' }) =>
    `/protocol-collection/${id}/data`,
  protocol_collection_show_devices: ({ id = ':id' }) =>
    `/protocol-collection/${id}/devices`,
  protocol_collection_show_value_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/protocol-collection/${id}/inventory/${childId}`,
  protocol_collection_show_metadata_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/protocol-collection/${id}/data/${childId}`,
  protocol_collection_show_conditions: ({ id = ':id' }) =>
    `/protocol-collection/${id}/conditions`,
  // Protocol
  protocol_show: ({ id = ':id' }) => `/protocol/${id}`,
  protocol_print: ({ id = ':id' }) => `/protocol/${id}/print`,
  protocol_show_values: ({ id = ':id' }) =>
    `/protocol/${id}/inventory`,
  protocol_show_timers: ({ id = ':id' }) => `/protocol/${id}/timers`,
  protocol_show_steps: ({ id = ':id' }) => `/protocol/${id}/steps`,
  protocol_show_experiments: ({ id = ':id' }) =>
    `/protocol/${id}/experiments`,
  protocol_show_metadata: ({ id = ':id' }) => `/protocol/${id}/data`,
  protocol_show_devices: ({ id = ':id' }) =>
    `/protocol/${id}/devices`,
  protocol_show_value_show: ({ id = ':id', childId = ':childId' }) =>
    `/protocol/${id}/inventory/${childId}`,
  protocol_show_metadata_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/protocol/${id}/data/${childId}`,
  protocol_show_conditions: ({ id = ':id' }) =>
    `/protocol/${id}/conditions`,
  // EntityStateWorkflow
  entity_state_workflow_show: ({ id = ':id' }) =>
    `/status-workflow/${id}`,
  entity_state_workflow_show_experiment_workflow_template: ({
    id = ':id',
  }) => `/status-workflow/${id}/templates`,
  entity_state_workflow_show_experiment_workflow: ({ id = ':id' }) =>
    `/status-workflow/${id}/experiments`,
  entity_state_workflow_show_activity: ({ id = ':id' }) =>
    `/status-workflow/${id}/activity`,
  // Experiment Workflow
  experiment_workflow_print: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/print`,
  experiment_workflow_show: ({ id = ':id' }) =>
    `/experiment-workflow/${id}`,
  experiment_workflow_show_workflow: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/workflow`,
  experiment_workflow_show_metadata: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/data`,
  experiment_workflow_show_devices: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/devices`,
  experiment_workflow_show_values: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/inventory`,
  experiment_workflow_show_experiment_workflows: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/linked-experiments`,
  experiment_workflow_show_planning: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/planning`,
  experiment_workflow_show_thread: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/thread`,
  experiment_workflow_show_activity: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/activity`,
  experiment_workflow_show_state_workflow: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/state-workflow`,
  experiment_workflow_show_signatures: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/review`,
  experiment_workflow_show_conditions: ({ id = ':id' }) =>
    `/experiment-workflow/${id}/conditions`,
  experiment_workflow_show_value_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment-workflow/${id}/inventory/${childId}`,
  experiment_workflow_show_thread_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment-workflow/${id}/thread/${childId}`,
  experiment_workflow_show_metadata_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment-workflow/${id}/data/${childId}`,
  experiment_workflow_show_metadata_show_thread: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment-workflow/${id}/data/${childId}/thread`,
  experiment_workflow_show_step_show_thread: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment-workflow/${id}/step/${childId}/thread`,
  experiment_workflow_show_step_show_thread_show: ({
    id = ':id',
    childId = ':childId',
    threadId = ':threadId',
  }) =>
    `/experiment-workflow/${id}/step/${childId}/thread/${threadId}`,
  experiment_workflow_show_metadata_show_thread_show: ({
    id = ':id',
    childId = ':childId',
    threadId = ':threadId',
  }) =>
    `/experiment-workflow/${id}/data/${childId}/thread/${threadId}`,
  // Experiment
  experiment_show: ({ id = ':id' }) => `/experiment/${id}`,
  experiment_show_values: ({ id = ':id' }) =>
    `/experiment/${id}/inventory`,
  experiment_show_metadata: ({ id = ':id' }) =>
    `/experiment/${id}/data`,
  experiment_show_timers: ({ id = ':id' }) =>
    `/experiment/${id}/timers`,
  experiment_show_steps: ({ id = ':id' }) =>
    `/experiment/${id}/steps`,
  experiment_show_devices: ({ id = ':id' }) =>
    `/experiment/${id}/devices`,
  experiment_show_thread_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment/${id}/thread/${childId}`,
  experiment_show_conditions: ({ id = ':id' }) =>
    `/experiment/${id}/conditions`,
  experiment_print: ({ id = ':id' }) => `/experiment/${id}/print`,
  experiment_show_value_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment/${id}/inventory/${childId}`,
  experiment_show_metadata_show: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment/${id}/data/${childId}`,
  experiment_show_metadata_show_thread: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment/${id}/data/${childId}/thread`,
  experiment_show_step_show_thread: ({
    id = ':id',
    childId = ':childId',
  }) => `/experiment/${id}/step/${childId}/thread`,
  experiment_show_metadata_show_thread_show: ({
    id = ':id',
    childId = ':childId',
    threadId = ':threadId',
  }) => `/experiment/${id}/data/${childId}/thread/${threadId}`,
  experiment_show_step_show_thread_show: ({
    id = ':id',
    childId = ':childId',
    threadId = ':threadId',
  }) => `/experiment/${id}/step/${childId}/thread/${threadId}`,
  // Group
  group_index: () => '/workspaces',
  group_index_organization: () => '/workspaces/organization',
  group_show: ({ id = ':id' }) => `/workspace/${id}`,
  group_create: () => `/workspaces/create`,
  // Overview
  group_overview: ({ id = ':id' }) => `/workspace/${id}/overview`,
  // Inventory
  group_resources: ({ id = ':id' }) => `/workspace/${id}/resources`,
  group_resource_items: ({ id = ':id' }) =>
    `/workspace/${id}/resource-items`,
  group_devices: ({ id = ':id' }) => `/workspace/${id}/devices`,
  group_order_requests: ({ id = ':id' }) =>
    `/workspace/${id}/order-requests`,
  group_purchase_orders: ({ id = ':id' }) =>
    `/workspace/${id}/purchase-orders`,
  group_thread: ({ id = ':id' }) => `/workspace/${id}/discussion`,
  group_experiment_workflows: ({ id = ':id' }) =>
    `/workspace/${id}/experiment-workflows`,
  group_experiment_workflow_templates: ({ id = ':id' }) =>
    `/workspace/${id}/experiment-workflow-templates`,
  group_protocol_collections: ({ id = ':id' }) =>
    `/workspace/${id}/protocol-collections`,
  // Group settings
  group_settings: ({ id = ':id' }) => `/workspace/${id}/settings`,
  group_settings_notifications: ({ id = ':id' }) =>
    `/workspace/${id}/settings/notifications`,
  group_settings_experiment_workflow: ({ id = ':id' }) =>
    `/workspace/${id}/settings/experiment`,
  group_settings_experiment_workflow_templates: ({ id = ':id' }) =>
    `/workspace/${id}/settings/experiment/templates`,
  group_settings_experiment_entity_state_workflow: ({ id = ':id' }) =>
    `/workspace/${id}/settings/experiment/status-workflows`,
  group_settings_experiment_tags: ({ id = ':id' }) =>
    `/workspace/${id}/settings/experiment/tags`,
  group_settings_order_request_template: ({ id = ':id' }) =>
    `/workspace/${id}/settings/ordering/request-template`,
  group_settings_purchase_order_template: ({ id = ':id' }) =>
    `/workspace/${id}/settings/ordering/order-template`,
  group_settings_order_request_tags: ({ id = ':id' }) =>
    `/workspace/${id}/settings/ordering/request-tags`,
  group_settings_device_categories: ({ id = ':id' }) =>
    `/workspace/${id}/settings/device/categories`,
  group_settings_device_tags: ({ id = ':id' }) =>
    `/workspace/${id}/settings/device/tags`,
  group_settings_protocol_tags: ({ id = ':id' }) =>
    `/workspace/${id}/settings/protocol/tags`,
  group_settings_inventory_categories: ({ id = ':id' }) =>
    `/workspace/${id}/settings/inventory/categories`,
  group_settings_inventory_locations: ({ id = ':id' }) =>
    `/workspace/${id}/settings/inventory/locations`,
  group_settings_inventory_tags: ({ id = ':id' }) =>
    `/workspace/${id}/settings/inventory/tags`,
  group_settings_auto_sharing: ({ id = ':id' }) =>
    `/workspace/${id}/settings/auto-sharing`,
  group_users: ({ id = ':id' }) => `/workspace/${id}/settings/users`,
  group_settings_export: ({ id = ':id' }) =>
    `/workspace/${id}/settings/export`,
  group_settings_signatures: ({ id = ':id' }) =>
    `/workspace/${id}/settings/signatures`,
  group_resource_categories: ({ id = ':id' }) =>
    `/workspace/${id}/resource-categories`,
  group_device_categories: ({ id = ':id' }) =>
    `/workspace/${id}/device-categories`,
  group_resource_locations: ({ id = ':id' }) =>
    `/workspace/${id}/resource-locations`,
  group_tags: ({ id = ':id' }) => `/workspace/${id}/tags`,
  group_files: ({ id = ':id' }) => `/workspace/${id}/files`,
  // Device
  device_show: ({ id = ':id' }) => `/device/${id}`,
  device_show_permalink: ({ id = ':id' }) => `/device/${id}/qrcode`,
  device_show_metadata: ({ id = ':id' }) => `/device/${id}/metadata`,
  device_show_device_data: ({ id = ':id' }) => `/device/${id}/data`,
  device_show_thread: ({ id = ':id' }) => `/device/${id}/thread`,
  device_show_activity: ({ id = ':id' }) => `/device/${id}/activity`,
  // Sharelink
  sharelink_show: ({ token = ':token' }) => `/sharelink/${token}`,
  // Permalink
  permalink_show: ({ token = ':token' }) => `/perma-link/${token}`,
  // Notification
  notification_redirect: ({ id = ':id' }) =>
    `/notification/${id}/redirect`,
  notification_index: () => '/notification',
  // Resource Location
  resource_location_show: ({ id = ':id' }) =>
    `/resource-location/${id}`,
  resource_location_show_sublocations: ({ id = ':id' }) =>
    `/resource-location/${id}/sublocations`,
  resource_location_show_metadata: ({ id = ':id' }) =>
    `/resource-location/${id}/metadata`,
  resource_location_show_resource_items: ({ id = ':id' }) =>
    `/resource-location/${id}/items`,
  resource_location_show_thread: ({ id = ':id' }) =>
    `/resource-location/${id}/thread`,
  resource_location_show_activity: ({ id = ':id' }) =>
    `/resource-location/${id}/activity`,
  // Resource
  resource_show: ({ id = ':id' }) => `/resource/${id}`,
  resource_show_permalink: ({ id = ':id' }) =>
    `/resource/${id}/qrcode`,
  resource_show_order_requests: ({ id = ':id' }) =>
    `/resource/${id}/requests`,
  resource_show_metadata: ({ id = ':id' }) =>
    `/resource/${id}/metadata`,
  resource_show_resource_items: ({ id = ':id' }) =>
    `/resource/${id}/items`,
  resource_show_resource_item_template: ({ id = ':id' }) =>
    `/resource/${id}/item-template`,
  resource_show_experiment_workflows: ({ id = ':id' }) =>
    `/resource/${id}/experiments`,
  resource_show_thread: ({ id = ':id' }) => `/resource/${id}/thread`,
  resource_show_activity: ({ id = ':id' }) =>
    `/resource/${id}/activity`,
  resource_template_show: ({ id = ':id' }) =>
    `/resource-category/${id}`,
  resource_template_show_metadata: ({ id = ':id' }) =>
    `/resource-category/${id}/metadata`,
  resource_template_show_thread: ({ id = ':id' }) =>
    `/resource-category/${id}/thread`,
  resource_template_show_activity: ({ id = ':id' }) =>
    `/resource-category/${id}/activity`,
  resource_template_show_resource_item_template: ({ id = ':id' }) =>
    `/resource-category/${id}/item-template`,
  resource_template_show_imports: ({ id = ':id' }) =>
    `/resource-category/${id}/imports`,
  // Resource Item
  resource_item_show: ({ id = ':id' }) => `/resource-item/${id}`,
  resource_item_show_metadata: ({ id = ':id' }) =>
    `/resource-item/${id}/metadata`,
  resource_item_show_thread: ({ id = ':id' }) =>
    `/resource-item/${id}/thread`,
  resource_item_show_lineage: ({ id = ':id' }) =>
    `/resource-item/${id}/lineage`,
  resource_item_show_experiment_workflows: ({ id = ':id' }) =>
    `/resource-item/${id}/experiments`,
  resource_item_show_activity: ({ id = ':id' }) =>
    `/resource-item/${id}/activity`,
  resource_item_show_permalink: ({ id = ':id' }) =>
    `/resource-item/${id}/qrcode`,
  jupyter_authorize: () => '/jupyter/authorize',
  unhandled_error: () => '/unhandled-error',
  // Set up e2e authenticated state
  setup_e2e: ({ username = ':username' }) =>
    `/setup/e2e/authenticated/${username}`,
};

export const routes = Object.keys(routing);

/**
 * Service
 * @type {Object}
 */
export const navigation = {
  /**
   * Get the route name
   * @param  {string} name - Route key e.g. 'group_show'
   * @param  {Object} params - Additional params required for the route to be computed
   * @param  {bool} fullLink - To get absolute link rathen than relative
   * @param  {object} query - Query params
   * @return {string} - Route
   */
  get: (
    name: keyof typeof routing,
    params = {},
    absolute = false,
    query: Record<string, unknown> | null = null,
  ): string => {
    if (!(name in routing)) {
      throw new Error(`key ${name} not present in routing`);
    }
    let link = routing[name](params as any);
    if (query) {
      link = `${link}?${qs.stringify(query)}`;
    }
    if (!absolute) {
      return link;
    }

    const { hostname, port, protocol } = window.location;
    const url = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    return `${url}${link}`;
  },
};
