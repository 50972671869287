/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterCreatedAt = new PostFilter([
  ['created_at', PostFilterComparison.gte],
  ['created_at', PostFilterComparison.lte],
]);
