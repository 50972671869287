/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const organizationGetMetabaseActionType =
  'organization_get_metabase';

export const organizationGetMetabaseAction = () =>
  sfApi.get({
    type: 'ORGANIZATION_GET_METABASE',
    route: {
      custom: 'app_api_organizationmetabase_getorganizationmetabase',
    },
    meta: {
      action_type: organizationGetMetabaseActionType,
      customReducer: 1,
    },
  });
