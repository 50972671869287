/**
 * Labstep
 *
 * @module screens/Group/Show/Center
 * @desc Group Show Center Screen
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import Route from 'labstep-web/core/Route';
import ScreensProtocolCollectionIndex from 'labstep-web/screens/ProtocolCollection/Index';
import ScreensExperimentWorkflowIndex from 'labstep-web/screens/ExperimentWorkflow/Index';
import ScreensExperimentWorkflowIndexTemplate from 'labstep-web/screens/ExperimentWorkflow/Index/Template';
import ScreensFileIndex from 'labstep-web/screens/File/Index';
import ScreensSettings from 'labstep-web/screens/Group/Show/Settings';
import ScreensOverview from 'labstep-web/screens/Overview';
import ScreensThread from 'labstep-web/screens/Thread';
import ScreensOrders from 'labstep-web/screens/Orders';
import ScreensInventory from 'labstep-web/screens/Inventory';
import ScreensDeviceIndex from 'labstep-web/screens/Device/Index';
import ScreensResourceLocationIndex from 'labstep-web/screens/ResourceLocation/Index';
import ScreensResourceTemplateIndex from 'labstep-web/screens/ResourceTemplate/Index';
import ScreensDeviceIndexTemplate from 'labstep-web/screens/Device/Index/Template';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { ICenterProps } from './types';

const Center: React.FC<ICenterProps> = ({ group }) => (
  <>
    <Route
      exact
      path={navigation.get('group_files')}
      render={(): React.ReactElement => (
        <ScreensFileIndex group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_experiment_workflows')}
      render={(): React.ReactElement => (
        <ScreensExperimentWorkflowIndex group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_experiment_workflow_templates')}
      render={(): React.ReactElement => (
        <ScreensExperimentWorkflowIndexTemplate group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_protocol_collections')}
      render={(): React.ReactElement => (
        <ScreensProtocolCollectionIndex group={group} />
      )}
    />
    <Route
      exact
      path={[
        navigation.get('group_resources'),
        navigation.get('group_resource_items'),
      ]}
      render={(): React.ReactElement => (
        <PremiumFeatureScreenGuard
          premiumFeature="inventory"
          children={<ScreensInventory group={group} />}
        />
      )}
    />
    <Route
      exact
      path={[
        navigation.get('group_order_requests'),
        navigation.get('group_purchase_orders'),
      ]}
      render={(): React.ReactElement => (
        <PremiumFeatureScreenGuard
          premiumFeature="order_management"
          children={<ScreensOrders group={group} />}
        />
      )}
    />
    <Route
      exact
      path={[
        navigation.get('group_settings'),
        navigation.get('group_settings_notifications'),
        navigation.get('group_settings_experiment_workflow'),
        navigation.get(
          'group_settings_experiment_workflow_templates',
        ),
        navigation.get(
          'group_settings_experiment_entity_state_workflow',
        ),
        navigation.get('group_settings_experiment_tags'),
        navigation.get('group_settings_protocol_tags'),
        navigation.get('group_settings_inventory_categories'),
        navigation.get('group_settings_inventory_locations'),
        navigation.get('group_settings_inventory_tags'),
        navigation.get('group_settings_device_categories'),
        navigation.get('group_settings_device_tags'),
        navigation.get('group_settings_order_request_template'),
        navigation.get('group_settings_order_request_tags'),
        navigation.get('group_settings_purchase_order_template'),
        navigation.get('group_settings_auto_sharing'),
        navigation.get('group_settings_export'),
        navigation.get('group_users'),
        navigation.get('group_settings_signatures'),
      ]}
      render={(): React.ReactElement => (
        <ScreensSettings group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_resource_locations')}
      render={(): React.ReactElement => (
        <ScreensResourceLocationIndex group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_resource_categories')}
      render={(): React.ReactElement => (
        <ScreensResourceTemplateIndex group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_overview')}
      render={(): React.ReactElement => (
        <ScreensOverview group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_thread')}
      render={(): React.ReactElement => (
        <ScreensThread thread={group.thread} group={group} />
      )}
    />
    <Route
      exact
      path={navigation.get('group_devices')}
      render={(): React.ReactElement => (
        <PremiumFeatureScreenGuard premiumFeature="devices">
          <ScreensDeviceIndex group={group} />
        </PremiumFeatureScreenGuard>
      )}
    />
    <Route
      exact
      path={navigation.get('group_device_categories')}
      render={(): React.ReactElement => (
        <ScreensDeviceIndexTemplate group={group} />
      )}
    />
  </>
);

export default Center;
