/**
 * Labstep.
 *
 * @module services/stripe
 * @desc Wrapper around Stripe JS SDK
 * @see https://github.com/stripe/stripe-js/issues/43
 */

import { Stripe, loadStripe } from '@stripe/stripe-js';
import { configService } from './config.service';
import { navigation } from './navigation';
import { windowService } from './window.service';

export class StripeService {
  public static async loadStripeClient(): Promise<Stripe | null> {
    if (configService.stripeApiKey) {
      return loadStripe(configService.stripeApiKey);
    }
    return null;
  }

  public static getStripePortal = (
    organization,
    priceId,
    currency,
    createStripeCheckoutSession,
  ) => {
    const url = organization
      ? navigation.get(
          'organization_show_billing',
          { identifier: organization.identifier },
          true,
        )
      : navigation.get('root', {}, true);

    createStripeCheckoutSession(
      {
        price_id: priceId,
        currency,
        quantity: 1,
        success_url: url,
        cancel_url: url,
      },
      {
        onSuccess: ({ response }) => {
          windowService.setLocation(response.url);
        },
      },
    );
  };
}
