/**
 * Labstep
 *
 * @module components/UserGroupNotification/Table
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import UserGroupNotificationActionUpdateMode from 'labstep-web/components/UserGroupNotification/Action/UpdateMode';
import { NotificationTypeValues } from 'labstep-web/constants/user-group-notification';
import SimpleTableCollapsible from 'labstep-web/core/Table/Simple/Collapsible';
import { UserGroupNotification } from 'labstep-web/models/user-group-notification.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { IUserGroupNotificationTableProps } from './types';

export const columns = [
  {
    header: 'Notify me when...',
    content: (notificationType) => (
      <span style={{ marginLeft: '50px' }}>
        {notificationType.name}
      </span>
    ),
    cellProps: { width: 9 },
  },
  {
    header: 'for...',
    content: (notificationType) => {
      const typesWithSettings = Object.keys(
        NotificationTypeValues,
      ).reduce((result, key) => {
        const values = Object.keys(NotificationTypeValues[key]).map(
          (subkey: string) => ({
            ...NotificationTypeValues[key][subkey],
            key: subkey,
          }),
        );

        return [...result, ...values];
      }, []);
      const setting = typesWithSettings.find(
        (typeWithSettings: any) =>
          typeWithSettings.key ===
          notificationType.userGroupNotification.notification_type,
      );

      if (setting && setting.mode === true) {
        return (
          <UserGroupNotificationActionUpdateMode
            userGroupNotification={
              notificationType.userGroupNotification
            }
          />
        );
      }

      return null;
    },
    cellProps: { style: { width: 300 } },
  },
  {
    header: 'via web app',
    content: (notificationType) => (
      <EntityActionUpdate
        optimistic
        entityName={UserGroupNotification.entityName}
        id={notificationType.userGroupNotification.id}
        body={{
          is_push: !notificationType.userGroupNotification.is_push,
        }}
        actionComponentProps={{
          type: 'checkbox',
          elementProps: {
            checked: notificationType.userGroupNotification.is_push,
            toggle: false,
          },
        }}
      />
    ),
    cellProps: { style: { width: 100 } },
  },
  {
    header: 'via email',
    content: (notificationType) => (
      <EntityActionUpdate
        optimistic
        entityName={notificationType.userGroupNotification.entityName}
        id={notificationType.userGroupNotification.id}
        body={{
          is_email: !notificationType.userGroupNotification.is_email,
        }}
        actionComponentProps={{
          type: 'checkbox',
          elementProps: {
            checked: notificationType.userGroupNotification.is_email,
            toggle: false,
          },
        }}
      />
    ),
    cellProps: { style: { width: 100 } },
  },
];

export const getSections = (
  userGroupNotifications: UserGroupNotification[],
) => {
  const sections = Object.keys(NotificationTypeValues).map(
    (notificationCategory) => ({
      entityName: notificationCategory,
      collapsible: true,
      name: getHumanReadableEntityName(
        notificationCategory,
        true,
        true,
      ),
      rows: Object.keys(NotificationTypeValues[notificationCategory])
        .filter(
          (notificationType) =>
            !!userGroupNotifications.find(
              (u) => u.notification_type === notificationType,
            ),
        )
        .map((notificationType) => ({
          name: NotificationTypeValues[notificationCategory][
            notificationType
          ].label,
          userGroupNotification: userGroupNotifications.find(
            (u) => u.notification_type === notificationType,
          ),
        })),
    }),
  );

  const premiumFilteredSections = usePremiumFeatureStatus(
    sections,
    'entityName',
    'categories',
  );

  return premiumFilteredSections;
};

const UserGroupNotificationTable: React.FC<
  IUserGroupNotificationTableProps
> = ({ userGroupNotifications }) => {
  return (
    <SimpleTableCollapsible
      columns={columns}
      sections={getSections(userGroupNotifications)}
    />
  );
};

export default UserGroupNotificationTable;
