/**
 * Labstep
 *
 * @module components/Layout/FullScreen/Links
 * @desc Labstep Layout
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import NavLink from 'labstep-web/core/NavLink';
import Dropdown from 'labstep-web/core/Dropdown';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { matchPath } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import { IRouting } from 'labstep-web/services/navigation.types';
import { NavLinkProps } from 'react-router-dom';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import styles from './styles.module.scss';
import { ILinksProps } from './types';
import { extraLinks, primaryLinks } from './constants';

export const Links: React.FC<ILinksProps> = ({
  desktop,
  group,
  showOverview,
}) => {
  const groupId = group.id;
  return (
    <>
      <div>
        {primaryLinks
          .filter((link, index) => showOverview || index !== 0)
          .map((link) => {
            const isActive: NavLinkProps['isActive'] = (
              match,
              location,
            ) =>
              link.routes
                ? !!link.routes.find(
                    (route: keyof IRouting) =>
                      !!matchPath(
                        location.pathname,
                        navigation.get(route),
                      ),
                  )
                : !!matchPath(
                    location.pathname,
                    navigation.get(link.route.to),
                  );
            return (
              <React.Fragment key={link.route.to}>
                {desktop ? (
                  <Popup
                    inverted
                    trigger={
                      <NavLink
                        className={styles.iconLink}
                        to={link.route.to}
                        params={{ id: groupId }}
                        isActive={isActive}
                        exact
                      >
                        <Icon name={link.icon} />
                        {link.premiumFeature && (
                          <PremiumFeatureLockIcon
                            premiumFeature={link.premiumFeature}
                          />
                        )}
                      </NavLink>
                    }
                    content={link.text}
                    on="hover"
                    position="right center"
                  />
                ) : (
                  <NavLink
                    className={styles.link}
                    to={link.route.to}
                    params={{ id: groupId }}
                    isActive={isActive}
                    exact
                  >
                    <Icon size="small" name={link.icon} />
                    {link.text}
                    {link.premiumFeature && (
                      <PremiumFeatureLockIcon
                        premiumFeature={link.premiumFeature}
                      />
                    )}
                  </NavLink>
                )}

                <div className={styles.border} />
              </React.Fragment>
            );
          })}
        {!!extraLinks.length && (
          <Dropdown
            className={styles.moreLinks}
            pointing="top left"
            trigger={<Icon size="small" name="ellipsis horizontal" />}
            icon={null}
          >
            {extraLinks.map((link) => (
              <Dropdown.Item
                key={link.route.to}
                as={NavLink}
                unstyled
                to={link.route.to}
                params={{ id: groupId }}
              >
                <Icon size="small" name={link.icon} />
                {link.text}
              </Dropdown.Item>
            ))}
          </Dropdown>
        )}
      </div>
    </>
  );
};

export const LinksActiveGroup: React.FC<
  Omit<ILinksProps, 'group'>
> = (props) => {
  const { activeGroup } = useActiveGroup();
  if (!activeGroup) {
    return null;
  }
  return <Links group={activeGroup} {...props} />;
};

export default LinksActiveGroup;
