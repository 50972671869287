/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/FreeTrial
 * @desc Organization plan card for free trial
 */

import React from 'react';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import Header from 'labstep-web/core/Header';
import SubHeader from 'labstep-web/core/SubHeader';
import OrganizationPlanActionUpgrade from '../../Action/Upgrade';
import styles from '../styles.module.scss';
import { OrganizationPlanCardFreeTrialProps } from './types';

export const OrganizationPlanCardFreeTrial: React.FC<
  OrganizationPlanCardFreeTrialProps
> = ({ organization }) => (
  <OrganizationPlanCard
    icon="clipboard list"
    header="Plan"
    secondary={
      <>
        <Header noMargin>Free Trial</Header>
        <SubHeader className={styles.orange}>
          {organization.printDaysRemaining}
        </SubHeader>
        <div className={styles.upgrade}>
          <OrganizationPlanActionUpgrade
            organization={organization}
          />
        </div>
      </>
    }
  />
);

export default OrganizationPlanCardFreeTrial;
