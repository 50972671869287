/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import ExperimentCard from 'labstep-web/components/Experiment/Card';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import List from 'labstep-web/core/List';
import { IExperimentListProps } from './types';

export const ExperimentList: React.FC<IExperimentListProps> = ({
  experiments,
  experimentWorkflowId,
  // status,
}) => {
  const canEdit = useHasAccess(
    ExperimentWorkflow.entityName,
    experimentWorkflowId,
    Action.edit,
  );
  return (
    <div id="experiment-list">
      <List>
        {experiments
          .sort((a, b) => a.position - b.position)
          .map((experiment) => (
            <EntityDraggable
              key={`experiment-${experiment.id}`}
              entityId={experiment.id}
              entityName={Experiment.entityName}
              disabled={!canEdit}
            >
              <ExperimentCard experiment={experiment} />
            </EntityDraggable>
          ))}
      </List>
    </div>
  );
  // return (
  //   <EntitySortableActionContainer>
  //     {({ disabled: sortDisabled, getSortAction }) => {
  //       const disabled = sortDisabled || !canEdit;
  //       return (
  //         <div id="experiment-list">
  //           <EntitySortableList
  //             items={experiments}
  //             entityName={Experiment.entityName}
  //             disabled={disabled}
  //             status={status}
  //             renderItem={(item): JSX.Element => (
  //               <SortableItem
  //                 key={item.id}
  //                 id={item.id}
  //                 disabled={disabled}
  //               >
  //                 <EntityDraggable
  //                   key={`experiment-${item.id}`}
  //                   entityId={item.id}
  //                   entityName={Experiment.entityName}
  //                 >
  //                   <ExperimentCard
  //                     experiment={item as Experiment}
  //                     dragHandle={
  //                       canEdit && (
  //                         <SortableItemDragHandle
  //                           children={getSortAction(item.id)}
  //                         />
  //                       )
  //                     }
  //                   />
  //                 </EntityDraggable>
  //               </SortableItem>
  //             )}
  //           />
  //         </div>
  //       );
  //     }}
  //   </EntitySortableActionContainer>
  // );
};

export default ExperimentList;
