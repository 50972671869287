/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Submit
 * @desc Submit the changes
 */

import { StripeContainer } from 'labstep-web/containers/Stripe';
import { StripeService } from 'labstep-web/services/stripe.service';
import React, { useEffect, useState } from 'react';
import { OrganizationPlanManagerSubmitProps } from './types';

export const Submit = ({
  organization,
  priceId,
  currency,
  createStripeCheckoutSession,
}: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      StripeService.getStripePortal(
        organization,
        priceId,
        currency,
        createStripeCheckoutSession,
      );
    }
  }, [
    organization,
    priceId,
    currency,
    createStripeCheckoutSession,
    isLoaded,
    setIsLoaded,
  ]);

  return <div>Redirecting to Stripe...</div>;
};

export const OrganizationPlanManagerSubmit: React.FC<
  OrganizationPlanManagerSubmitProps
> = ({ organization, priceId, currency }) => {
  return (
    <StripeContainer>
      {({ createStripeCheckoutSession }) => (
        <Submit
          organization={organization}
          priceId={priceId}
          currency={currency}
          createStripeCheckoutSession={createStripeCheckoutSession}
        />
      )}
    </StripeContainer>
  );
};

export default OrganizationPlanManagerSubmit;
