/**
 * Labstep
 *
 * @module components/Metadata/List
 * @desc Experiment Value list
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import MetadataActionCreateFile from 'labstep-web/components/Metadata/Action/Create/File';
import MetadataCard from 'labstep-web/components/Metadata/Card';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import List from 'labstep-web/core/List';
import { IMetadataCardListProps } from './types';

export const MetadataCardList: React.FC<IMetadataCardListProps> = ({
  entity,
  draggable = true,
  hideActions,
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.id,
    Action.edit,
  );
  return (
    <MetadataActionCreateFile
      metadataThreadId={entity.metadata_thread.id}
    >
      <ReadOnMountHOC
        type="cursor"
        entityName={Metadata.entityName}
        params={{
          metadata_thread_id: entity.metadata_thread.id,
          has_variable_template: false,
        }}
        children={({ entities }) => (
          <List>
            {entities.map((item: Metadata) => (
              <EntityDraggable
                key={item.id}
                entityId={item.id}
                entityName={Metadata.entityName}
                disabled={!draggable || !canEdit}
              >
                <MetadataCard
                  entity={entity}
                  metadata={item as Metadata}
                  hideActions={hideActions}
                />
              </EntityDraggable>
            ))}
          </List>
        )}
      />
    </MetadataActionCreateFile>
  );
  // return (
  //   <EntitySortableActionContainer>
  //     {({ disabled: sortDisabled, getSortAction }) => {
  //       const disabled = !draggable || sortDisabled || !canEdit;
  //       return (
  //         <MetadataActionCreateFile
  //           metadataThreadId={entity.metadata_thread.id}
  //         >
  //           <ReadOnMountHOC
  //             type="cursor"
  //             entityName={Metadata.entityName}
  //             params={{
  //               metadata_thread_id: entity.metadata_thread.id,
  //               has_variable_template: false,
  //             }}
  //             children={({ entities, status }) => (
  //               <EntitySortableList
  //                 items={entities.filter((e: any) => !e.deleted_at)}
  //                 entityName={Metadata.entityName}
  //                 disabled={disabled}
  //                 status={status}
  //                 renderItem={(item) => (
  //                   <SortableItem
  //                     key={item.id}
  //                     id={item.id}
  //                     disabled={disabled}
  //                   >
  //                     <EntityDraggable
  //                       key={item.id}
  //                       entityId={item.id}
  //                       entityName={Metadata.entityName}
  //                       disabled={!draggable || !canEdit}
  //                     >
  //                       <MetadataCard
  //                         entity={entity}
  //                         metadata={item as Metadata}
  //                         dragHandle={
  //                           canEdit && (
  //                             <SortableItemDragHandle
  //                               children={getSortAction(item.id)}
  //                             />
  //                           )
  //                         }
  //                         hideActions={hideActions}
  //                       />
  //                     </EntityDraggable>
  //                   </SortableItem>
  //                 )}
  //               />
  //             )}
  //           />
  //         </MetadataActionCreateFile>
  //       );
  //     }}
  //   </EntitySortableActionContainer>
  // );
};

export default MetadataCardList;
