/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterAvailableItemsMin = new PostFilter([
  ['available_resource_item_count', PostFilterComparison.gte],
]);

export const PostFilterAvailableItemsMax = new PostFilter([
  ['available_resource_item_count', PostFilterComparison.lt],
]);
