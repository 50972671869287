/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Resource/Select
 * @desc Search resource/resourceItem to filter ExperimentWorkflows
 */

import FilterEntity from 'labstep-web/components/Filter/Entity';
import Container from 'labstep-web/components/Layout/Container';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import {
  IExperimentWorkflowFilterResourceSelectProps,
  IResourceChildrenProps,
  IResourceItemChildrenProps,
} from './types';

export const ExperimentWorkflowFilterResourceSelect: React.FC<
  IExperimentWorkflowFilterResourceSelectProps
> = ({ setParams, searchParams }) => {
  let children = null;
  if ('resource_item_id' in searchParams) {
    children = (
      <EntityReadEntityContainer
        entityName={ResourceItem.entityName}
        id={searchParams.resource_item_id || 'random'}
      >
        {({ entity: resourceItem }: IResourceItemChildrenProps) => (
          <>
            <SearchSelect
              entityName={Resource.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  resource_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                  resource_item_id: undefined,
                });
              }}
              value={resourceItem.resource}
            />
            <br />
            <b>Specific item used</b>
            <SearchSelect
              menuPlacement="top"
              entityName={ResourceItem.entityName}
              params={{ resource_id: resourceItem.resource.id }}
              onChange={(selectedOption) => {
                if (!selectedOption) {
                  setParams({
                    resource_id: resourceItem.resource.id,
                    resource_item_id: undefined,
                  });
                } else {
                  setParams({
                    resource_id: undefined,
                    resource_item_id: selectedOption.id,
                  });
                }
              }}
              value={resourceItem}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  } else {
    children = (
      <EntityReadEntityContainer
        entityName={Resource.entityName}
        id={searchParams.resource_id || 'random'}
      >
        {({ entity: resource }: IResourceChildrenProps) => (
          <>
            <SearchSelect
              entityName={Resource.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  resource_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                  resource_item_id: undefined,
                });
              }}
              value={resource || null}
            />
            <br />
            <b>Specific item used</b>
            {resource ? (
              <SearchSelect
                menuPlacement="top"
                entityName={ResourceItem.entityName}
                params={{ resource_id: resource.id }}
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    setParams({ resource_item_id: undefined });
                  } else {
                    setParams({
                      resource_id: undefined,
                      resource_item_id: selectedOption.id,
                    });
                  }
                }}
                value={null}
              />
            ) : (
              <p>Select a resource above first</p>
            )}
          </>
        )}
      </EntityReadEntityContainer>
    );
  }

  return (
    <Modal
      header="Filter by Resource"
      content={({ toggleModal }) => (
        <PremiumFeatureScreenGuard
          unstyled
          premiumFeature="inventory"
        >
          <div>
            <Container>{children}</Container>
            <LayoutContainerActionButtons>
              <ActionComponent
                type="button"
                text="Done"
                onClick={toggleModal}
              />
            </LayoutContainerActionButtons>
          </div>
        </PremiumFeatureScreenGuard>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.resource.primary}
          text="Resource used"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export class ExperimentWorkflowFilterResourceSelectContainer extends React.Component<
  IExperimentWorkflowFilterResourceSelectProps,
  { shouldRenderWithFilter: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderWithFilter:
        'resource_id' in props.searchParams ||
        'resource_item_id' in props.searchParams,
    };
  }

  render() {
    const { searchParams, setParams } = this.props;
    const children = (
      <ExperimentWorkflowFilterResourceSelect
        setParams={setParams}
        searchParams={searchParams}
      />
    );
    if (!this.state.shouldRenderWithFilter) {
      return children;
    }
    let entityName;
    let searchParamKey;

    if (searchParams.resource_id) {
      entityName = Resource.entityName;
      searchParamKey = 'resource_id';
    } else if (searchParams.resource_item_id) {
      entityName = ResourceItem.entityName;
      searchParamKey = 'resource_item_id';
    } else {
      return children;
    }

    return (
      <FilterEntity
        entityName={entityName}
        searchParamKey={searchParamKey}
        searchParams={searchParams}
      >
        {() => children}
      </FilterEntity>
    );
  }
}

export default ExperimentWorkflowFilterResourceSelectContainer;
